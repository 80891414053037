<template>
    <div class="detailview-wrapper">
        <!-- <div class="top-box ds-flex end-start">
            <div class="back-btn-box ds-flex center-start" @click="backToList()">
                <img src="@/assets/news/back.png">
                <div class="back-btn">Back to list</div>
            </div>
            <img class="top-box-bg" src="@/assets/news/worldwide.png" />
        </div> -->
        <div class="news-detail-box">
            <div class="news-detail-title-box ds-flex center-start">
                <div class="ds-flex center-between">
                    <input readonly type="text" v-model="ir.title"/>
                    <p>{{ir.date}}</p>
                </div>
            </div>
            <div class="news-detail-contents-box">
                <div id="viewer" ref="viewer"></div>
            </div>
            <div class="detail-btn" @click="backToList()">
                <svg width="20" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 10C1.5 14.9706 5.52944 19 10.5 19C15.4706 19 19.5 14.9706 19.5 10C19.5 5.02944 15.4706 1 10.5 1C5.52944 1 1.5 5.02944 1.5 10ZM0.5 10C0.5 15.5228 4.97715 20 10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10ZM5.14645 9.64645L8.32843 6.46447C8.52369 6.2692 8.84027 6.2692 9.03553 6.46447C9.2308 6.65973 9.2308 6.97631 9.03553 7.17157L6.70711 9.5L15.5 9.5V10.5L6.70711 10.5L9.03553 12.8284C9.2308 13.0237 9.2308 13.3403 9.03553 13.5355C8.84027 13.7308 8.52369 13.7308 8.32843 13.5355L5.14645 10.3536C4.95118 10.1583 4.95118 9.84171 5.14645 9.64645Z" fill="#1E1E1E"/>
                </svg>
                목록으로 이동
            </div>
            <!-- <div class="news-detail-list-box">
                <div class="prev-box ds-flex center-start" @click="movePage(listIdx+1)">
                    <div class="gray-box ds-flex center-center">
                        <img src="@/assets/news/up.png">
                        <p>이전글</p>
                    </div>
                    <div class="gray-title-box ds-flex center-start">
                        <p>이전글이 없습니다.</p>
                    </div>
                </div>
                <div class="next-box ds-flex center-start" @click="movePage(listIdx-1)">
                    <div class="gray-box ds-flex center-center">
                        <img src="@/assets/news/down.png">
                        <p>다음글</p>
                    </div>
                    <div class="gray-title-box ds-flex center-start">
                        <p>다음글이 없습니다.</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
// import Editor from '@toast-ui/editor';
import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';

import '@toast-ui/editor/dist/toastui-editor.css'
import {API} from "../../api.js";
import moment from "moment";

export default {
  name: 'IrDetailView',
  components: {
  },
  data: () => ({
    idx: '',
    listIdx: '',
    ir: '',
    viewer: null,
    irList: [],
  }),
  methods: {
    backToList() {
        this.$router.push({
            name:'IR',
            // query: {idx: idx}
        });
    },
    // 클릭한 ir의 컨텐츠를 가져온다.
    getIrContents(idx) {
        let singleton = this;
        API.getIrContents(idx, function(status, res){
            singleton.ir = res.data[0];
            singleton.ir.date = moment(singleton.ir.date).format('YYYY-MM-DD');
            singleton.setViewer();
        })
    },
    // getListIdx(list) {
    //     for(let i=0; i<list.length; i++) {
    //         if(list[i].idx === Number(this.idx)) {
    //             console.log('idx = ', list[i].idx , this.idx);
    //             this.listIdx = i;
    //             console.log('this.listIdx >> ',this.listIdx);
    //         }
    //     }
    // },
    setViewer() {
        this.viewer = new Viewer({
            el: this.$refs.viewer,
            initialValue: this.ir.contents
      });
    },
    // movePage(idx) { // 이전글 다음글 클릭 시 동작
    //     this.$router.push({
    //         name:'irDetailView',
    //         query: {idx: idx}
    //     });
    // }
  },
  mounted() {
    this.idx = this.$route.query.idx;
    console.log(this.idx);
    // this.getirList();
    this.getIrContents(this.idx);
  },
  created() {
  }
}
</script>

<style scoped>
/* viewer */
#viewer img {
    max-width: 1200px;
}

#viewer {
    text-align: left;
}

.toastui-editor-contents strong {
    font-weight: normal;
}

.toastui-editor-contents p {
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    margin: 0;
    color: #1E1E1E;
}

.toastui-editor-contents h3, .toastui-editor-contents h4 {
    font-weight: normal;
    font-size: 18px;
    text-align: left;
    line-height: 40px;
}

.toastui-editor-contents img {
    width: 1000px;
    display: flex;
    margin: auto;
}

.toastui-editor-contents ul p, .toastui-editor-contents ol p {
    font-size: 18px;
    text-align: left;
}

.toastui-editor-contents ol > li::before {
    font-size: 18px;
    display: flex;
    align-items: center;
    height: 28px;
    color: #222;
}
/* viewer */

.detailview-wrapper {
    color: #191E2C;
    border-top: 1.5px solid #00ACEB;
}

.detailview-wrapper .top-box {
    position: relative;
    padding-top: 250px;
    border-bottom: 1.5px solid #DDDDDD;
    overflow: hidden;
    height: 452px;
    box-sizing: border-box;
}

.top-box-bg {
    position: absolute;
    bottom: -130px;
    right: 120px;
}

.back-btn-box {
    margin-left: 50px;
    margin-bottom: 32px;
    cursor: pointer;
}

.back-btn-box img {
    margin-right: 16px;
}

.back-btn-box .back-btn {
    font-size: 24px;
    font-weight: bold;
}

.news-detail-box {
    padding: 0px 158px;
}

.news-detail-title-box {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #DBDEE7;
    padding: 0 30px;
    box-sizing: border-box;
}

.news-detail-title-box > div {
    width: 100%;
}

.news-detail-title-box h3 {
    font-size: 20px;
    color: #7A7A7A;
    text-align: left;
}

.news-detail-title-box input {
    color: #1E1E1E;
    font-size: 16px;
    font-weight: bold;
    border: none;
    width: 90%;
}

.news-detail-title-box p {
    color: #979CA8;
    font-size: 14px;
}

.news-detail-contents-box {
    padding: 20px 30px;
    border-bottom: 2px solid #1E1E1E;
}

.news-detail-contents-box a {
    padding-left: 10px;
}

.news-detail-img {
    width: 100%;
}

.news-detail-contents {
    min-height: 30vh;
    height: 100%;
    border: none;
    resize: none;
    width: 100%;
    color: #323232;
    font-size: 1.6rem;
    padding: 32px 0;
    font-size: 20px;
    text-align: left;
    line-height: 30px;
}

.news-detail-list-box {
    width: 100%;
    border-top: 1px solid #DDDDDD;
}

.prev-box, .next-box {
    border-bottom: 1px solid #DDDDDD;
    cursor: pointer;
}

.gray-box {
    background-color: #F2F2F2;
    width: 205px;
    height: 80px;
}

.gray-box > img {
    margin-right: 16px;
}

.gray-title-box {
    background-color: #fff;
    height: 80px;
}

.gray-title-box p {
    padding: 0 40px;
}

.detail-btn {
    width: 150px;
    height: 40px;
    border: 1.5px solid #1E1E1E;
    color: #1E1E1E;
    font-size: 14px;
    font-weight: 600;
    padding: 12px 15px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    margin: 60px auto 80px auto;
}

.detail-btn:hover {
  color: #fff;
  background-color: #1E1E1E;
  transition: .3s;
}

.detail-btn:hover path {
  fill: #fff;
  transition: .3s;
}

.news-detail-contents-box {
    min-height: calc(100vh - 1041px);
}

@media screen and (max-width: 1240px) {
    .news-detail-title-box input {
        font-size: 13px;
    }

    .news-detail-title-box p {
        font-size: 10px;
    }

    .news-detail-title-box {
        height: 40px;
    }

    .detail-btn {
        width: 115px;
        height: 30px;
        font-size: 12px;
        padding: 0 10px;
    }

    .detail-btn > svg {
        width: 16px;
        height: 16px;
    }
}

@media screen and (max-width: 768px) { 
    .detailview-wrapper {
        border-top: none;
    }

    .news-detail-box {
        padding: 0 40px;
    }

    .news-detail-title-box input {
        font-size: 13px;
        width: 85%;
        line-height: 14px;
    }

    .news-detail-title-box p {
        font-size: 10px;
    }

    .news-detail-title-box {
        height: 35px;
    }
}

@media screen and (max-width: 480px) {
    .news-detail-title-box input {
        font-size: 14px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .news-detail-title-box {
        height: 65px;
        padding: 0 20px;
    }

    .news-detail-title-box p {
        font-size: 12px;
    }

    .news-detail-contents-box {
        padding: 20px;
    }

    .detail-btn {
        width: 150px;
        height: 40px;
        font-size: 14px;
        padding: 0 15px;
        margin: 40px auto;
    }

    .detail-btn > svg {
        width: 20px;
        height: 20px;
    }

    .detailview-wrapper {
        padding: 0;
    }

    .news-detail-box {
        padding: 0;
        width: 360px;
        margin: auto;
    }
}
</style>
